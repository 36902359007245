<script setup lang="ts">
import AppButton from "@/Components/Shared/button/AppButton.vue";
import { router, useForm } from "@inertiajs/vue3";
import { toast } from "vue-sonner";
import {
    apiPathUrl,
    isGuest,
    isVerified,
    mustBeLogin,
    mustBeVerified,
} from "@/Utils/Helpers";
import AppIcon from "@/Components/Shared/icon/AppIcon.vue";
import { computed, ref } from "vue";
import { debounce } from "lodash-es";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/Components/Shared/tooltip";
import TheWishlistForm from "@/Components/App/forms/TheWishlistForm.vue";
import useEmitter from "@/Composables/useEmitter";
import {
    ButtonAlign,
    ButtonSize,
    ButtonVariant,
} from "@/Components/Shared/button";
import AppFormInputError from "@/Components/Shared/forms/AppFormInputError.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
const { emitter } = useEmitter();

type Props = {
    cardId: string;
    wishlist?: object;
    buttonVariant?: ButtonVariant;
    buttonAlign?: ButtonAlign;
    buttonSize?: ButtonSize | ButtonSize[];
};

const {
    cardId,
    wishlist = undefined,
    buttonVariant = undefined,
    buttonAlign = undefined,
    buttonSize = "small",
} = defineProps<Props>();

const form = useForm({
    quantity: wishlist ? wishlist.quantity : 1,
});

const errors = ref(null);

const addToWishlist = () => {
    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    router.post(
        apiPathUrl("/wishlists/add"),
        {
            card: cardId,
        },
        {
            preserveScroll: true,
            only: ["card", "cards", "wishlists", "deckCards"],
            onSuccess: () => {
                toast.error("Kaart toegevoegd aan verlanglijstje");
            },
            onError: () => {
                toast.error(
                    "Er ging iets fout bij het toevoegen aan je verlanglijstje",
                );
            },
        },
    );
};

const debouncedUpdateQuantity = debounce(quantity => {
    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    router.patch(
        apiPathUrl(`/wishlists/${cardId}`),
        {
            card: cardId,
            quantity: quantity,
        },
        {
            preserveScroll: true,
            only: ["card", "cards", "wishlists", "errors"],
            onSuccess: () => {
                toast.success("Hoeveelheid in verlanglijstje gewijzigd");
            },
            onError: (e) => {
                errors.value = e;
                toast.error(
                    "Er ging iets fout bij het wijzigen van de hoeveelheid",
                );
            },
        },
    );
}, 300);

const initialQuantity = ref(form.quantity);

const quantity = computed({
    get: () => form.quantity,
    set: value => {
        form.quantity = Math.max(1, value);
        if (initialQuantity.value !== form.quantity) {
            debouncedUpdateQuantity(form.quantity);
            initialQuantity.value = form.quantity;
        }
    },
});

const handleBlur = () => {
    if (initialQuantity.value !== form.quantity) {
        quantity.value = form.quantity;
    }
};

const handleEnter = event => {
    if (event.key === "Enter" && initialQuantity.value !== form.quantity) {
        quantity.value = form.quantity;
    }
};

const toggleWishlist = () => {
    if (wishlist) {
        removeFromWishlist();
    } else {
        addToWishlist();
    }
};

const removeFromWishlist = () => {
    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    router.delete(apiPathUrl(`/wishlists/${cardId}`), {
        preserveScroll: true,
        only: ["card", "cards"],
        onSuccess: () => {
            toast.error("Kaart verwijderd van verlanglijstje");
        },
        onError: () => {
            toast.error(
                "Er ging iets fout bij het verwijderen uit je verlanglijstje",
            );
        },
    });
};

const edit = () => {
    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    emitter.emit("modal", {
        component: TheWishlistForm,
        title: "Wijzig verlanglijstje",
        modalClass: "large",
        props: {
            cardId: cardId,
        },
    });
};
</script>

<template>
    <TooltipProvider>
        <div v-if="wishlist" class="flex flex-col border border-slate-300 rounded px-2 py-1">
            <AppText size="small" class="font-semibold w-full text-left mb-1" icon="wishlist" icon-size="small">
                Verlanglijstje
            </AppText>
            <div
                class="flex items-center gap-3"
            >
                <div class="flex">
                    <AppButton
                        size="small"
                        icon="minus"
                        class="rounded-r-none"
                        icon-size="xsmall"
                        :disabled="quantity <= 1"
                        @click="quantity = quantity - 1"
                    />
                    <input
                        v-model.number="form.quantity"
                        type="number"
                        min="1"
                        max="99"
                        name="quantity"
                        class="shrink-0 border-y border-x-0 py-0 px-1 text-base w-[42px] border-slate-200 leading-none text-center focus:ring-primary/10 focus:ring-4"
                        @blur="handleBlur"
                        @keyup.enter="handleEnter"
                    />
                    <AppButton
                        size="small"
                        icon="plus"
                        class="rounded-l-none"
                        icon-size="xsmall"
                        @click="quantity = quantity + 1"
                    />
                </div>
                <div class="flex gap-1">
                    <Tooltip>
                        <TooltipTrigger>
                            <AppButton
                                icon="edit"
                                size="small"
                                icon-size="xsmall"
                                variant="round"
                                @click="edit"
                            />
                        </TooltipTrigger>
                        <TooltipContent> Wijzig verlanglijstje </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                        <TooltipTrigger>
                            <AppButton
                                icon="cross"
                                size="small"
                                icon-size="xsmall"
                                variant="round"
                                @click="removeFromWishlist"
                            />
                        </TooltipTrigger>
                        <TooltipContent>
                            Verwijder uit verlanglijstje
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>

            <AppFormInputError
                v-if="errors && errors.quantity"
                :errors="errors.quantity"
            />
        </div>
        <AppButton
            v-else
            :key="!!wishlist"
            :size="buttonSize"
            :variant="buttonVariant"
            :align="buttonAlign"
            icon="wishlist"
            @click="toggleWishlist"
        >
            Verlanglijstje
        </AppButton>
    </TooltipProvider>
</template>
